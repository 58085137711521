/* Dashboard */
.message-log {
    max-height: 150px;
    overflow: scroll;
    width: 100%;
}

/* Speaker Table */

.table {
	border: 0 !important;
}

.table td {
	vertical-align: middle;
}

table th.highlight-left {
	border-top: 3px $primary solid !important;
	border-left: 3px $primary solid !important;
}
table th.highlight-right {
	border-top: 3px $primary solid !important;
	border-right: 3px $primary solid !important;
}
table td.highlight-left {
	border-left: 3px $primary solid !important;
}
table td.highlight-right {
	border-right: 3px $primary solid !important;
}
table tr:nth-last-child(2) td.highlight-left {
	border-bottom: 3px $primary solid !important;
}
table tr:nth-last-child(2) td.highlight-right {
	border-bottom: 3px $primary solid !important;
}

table .table-footer {
	border: 0;
	background-color: transparent !important;
}

table .table-footer td {
	border: 0;
	padding: 0.3rem 0.75rem;
	text-align: center;
}

@media (min-width: 1600px) {
    .container {
        max-width: 1550px;
    }
}
