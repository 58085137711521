.index {
    overflow: hidden;
}

#remote-media-div video {
    border-radius: 0.5px;
    width: 100%;
}

#remote-media-div.speaker video {
    position: relative;
    z-index: 5;
}

#local-media video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    cursor: move;
}

#local-media {
    z-index: 10;
    position: fixed;
    bottom: 10px;
    left: 10px;
    max-width: 25vw;

    transition: left 0.75s ease-in-out;
}

#local-media-tracks {
    height: calc(25vw * 9 / 16);
    overflow: hidden;
}

@media screen and (max-width: 800px) {
    #local-media {
        max-width: 35vw;
    }

    #local-media-tracks {
        height: calc(35vw * 9 / 16);
    }
}

@media screen and (max-width: 420px) {
    #local-media {
        max-width: 95vw;
        bottom: 50px;
    }

    #local-media-tracks {
        height: calc(95vw * 9 / 16);
    }
}

#local-media .btn-group {
    display: flex;
}

#local-media .btn {
    cursor: pointer;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

#local-media .btn.active, #local-media .btn:active {
    box-shadow: inset 0 0 3px #000;
}

.index {
    background-color: #f8fafc;
}

.footer {
    position: fixed;
    left: 50%;
    bottom: 10px;
    text-align: center;
    z-index: 10;
    transform: translateX(-50%);
}

.chat {
    position: absolute;
    right: 25px;
    top: 25px;
    background-color: #eeee;
    max-height: 87vh;
    width: 25%;
    overflow: scroll;
}

.chat-message-content {
    margin-bottom: 0;
}


#error-image {
    display: block;
    margin: 0 auto;
    max-width: 400px;
}

#browser-arrow {
    font-size: 100px;
    position: fixed;
}

.firefox-arrow {
    left: 18%;
    top: 0;
}

.chrome-arrow {
    left: 80px;
    top: 0;
}

.safari-arrow {
    display: none;
}

.unknown-arrow {
    display: none;
}

.input-selection-box {
    z-index: 100;
}

#local-media.localMediaHidden {
    left: -21.1vw !important;
}

#local-media.localMediaHidden video, #mute.localMediaHidden {
    transition: all 0.75s ease-in-out;
    opacity: 0;
}
