@import "borders";

a > .card {
    transition: background-color 0.5s;
}

a:hover > .card {
    background-color: $body-bg !important;
}

.no-margins {
    margin-top: revert;
    margin-bottom: revert;
}

.no-case-modification {
    text-transform: initial !important;
}
