.login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    background: rgba(255, 255, 255, 0.95);
    border-radius: .25rem;
    border: 1px solid #cbcdcd;
    -webkit-transition: height .35s;
    -o-transition: height .35s;
    transition: height .35s;
    width: -webkit-calc(100% - 4rem);
    width: calc(100% - 4rem);

    -webkit-box-shadow: 0 3px 14px 7px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 3px 14px 7px rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 14px 7px rgba(0, 0, 0, 0.15);
}

@media only screen and (min-width: 48em) {
    .login-box {
        width: 460px;
    }
}

body {
    background-image: url("/images/background/linien-gross-1-sw.svg");
    background-size: cover;
}

.navbar-brand img {
    height: 40px;
    margin-right: 10px;
}
