.shake {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.8s;

    /* When the animation is finished, start again */
    animation-iteration-count: 2;
}

.moveUpDown {
    animation: moveUpDown 1.5s;
    animation-iteration-count: infinite;
}

.dragging {
    border: 2px dashed #ccc !important;
    background: #eeeeee;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes moveUpDown {
    0%, 100% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0px, -10px);
    }
}
