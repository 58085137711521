.viewer {
    background-image: url("/images/background/testimage.jpg");
    background-color: black;
    cursor: none;
    overflow: hidden;
}

.viewer-video-wrapper video {
    height: 100vh;
    object-fit: cover;
    z-index: 2;
}

#viewer-target {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 10px;
    background-color: #000000eb;
    border-radius: 5px;
    z-index: 1;
}
